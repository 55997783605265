const CONSTANT = {
  COOKIE: {
    APP_VERSION: 'app-version',
    CART_HREF: 'cart-href',
  },
  HEADERS: {
    HEADER_APP_VERSION: 'X-LG-APP-VERSION',
    HEADER_ACCESS_TOKEN: 'X-LG-ACCESS-TOKEN',
    HEADER_ACCESS_TOKEN_LOWERCASE: 'x-lg-access-token',
    HEADER_CLIENT_TYPE: 'X-LG-CLIENT-TYPE',
  },
  APP_VERSION: {
    ANDROID: {
      CURRENT: '1.6.0',
      NEW: '1.7.0',
    },
  },
  ETC: {
    USER_REGION_TYPE_CODE: 'USER_REGION_TYPE_CODE',
  },
};

export default CONSTANT;

export const PREMIUM_BRAND_LIST = [
  '에르메스',
  '샤넬',
  '루이비통',
  '구찌',
  '프라다',
  '버버리',
  '셀린',
  '몽클레르',
  '발렌티노',
  '펜디',
  '디올',
  '로로 피아나',
  '미우미우',
  '키톤',
  '막스마라',
  '발렌시아가',
  '보테가 베네타',
  '톰 브라운',
];

export const HEX_OPACITY_SUFFIX = {
  0: '00',
  5: '0C',
  10: '19',
  15: '26',
  20: '33',
  25: '3F',
  30: '4C',
  35: '59',
  40: '66',
  45: '72',
  50: '7F',
  55: '8C',
  60: '99',
  65: 'A5',
  70: 'B2',
  75: 'BF',
  80: 'CC',
  85: 'D8',
  90: 'E5',
  95: 'F2',
  100: 'FF',
};

export const INTL_DEFAULT_OPTIONS: Intl.DateTimeFormatOptions = {
  year: 'numeric',
  month: 'numeric',
  day: 'numeric',
  hour: 'numeric',
  minute: 'numeric',
  second: 'numeric',
  hour12: false,
  timeZone: 'Asia/Seoul',
};

export const NON_AUTHENTICATION_API_LIST = [
  //   {
  //     method: 'get',
  //     url: '/v2/products/categories',
  //     exact: true,
  //   },
  //   {
  //     method: 'get',
  //     url: '/v2/products?',
  //     exact: false,
  //   },
  //   {
  //     method: 'get',
  //     url: '/v2/products/:productId',
  //     exact: false,
  //   },
  {
    method: 'get',
    url: '/v2/products/recommend',
    exact: true,
  },
  //   {
  //     method: 'get',
  //     url: '/v2/products/validation?',
  //     exact: false,
  //   },
  {
    method: 'get',
    url: '/v2/notices',
    exact: false,
  },
];

export const AUTHENTICATION_API_URL_LIST = ['/v2/products/basket', '/v2/products/order'];
