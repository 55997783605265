// 토스트
function showToast(text, isTop) {
  const message = {
    command: 'showToast',
    values: { text, topYn: isTop ? 'Y' : 'N' },
  };
  window.webkit.messageHandlers.wv.postMessage(message);
}

// 얼럿
function showAlert(title, messageText) {
  const message = {
    command: 'showAlert',
    values: { title, messageText },
  };
  window.webkit.messageHandlers.wv.postMessage(message);
}

// confirm
function showConfirm({
  title,
  message,
  cancelTitle,
  confirmTitle,
  cancelCallback,
  confirmCallback,
}) {
  window.webkit.messageHandlers.wv.postMessage({
    command: 'confirm',
    values: {
      title,
      message,
      confirm_title: confirmTitle,
      cancel_title: cancelTitle,
      confirm_callback: confirmCallback,
      cancel_callback: cancelCallback,
    },
  });
}

// 클립보드 복사
function copyToClipboard(text, messageText) {
  const message = {
    command: 'copyToClipboard',
    values: { text, message: messageText },
  };
  window.webkit.messageHandlers.wv.postMessage(message);
}

// 화면 닫기
function closeWindow() {
  const message = { command: 'closeWindow' };
  window.webkit.messageHandlers.wv.postMessage(message);
}

// 얼럿을 보여주고 화면 닫기
function showAlertAndCloseWindow(text) {
  const message = {
    command: 'showAlertAndCloseWindow',
    values: { text },
  };
  window.webkit.messageHandlers.wv.postMessage(message);
}

// 얼럿을 보여주고 화면 전체 닫기
function showAlertAndCloseAll(text) {
  const message = {
    command: 'showAlertAndCloseAll',
    values: { text },
  };
  window.webkit.messageHandlers.wv.postMessage(message);
}

// 트레킹 전송
function sendEvent(event) {
  const message = {
    command: 'sendEvent',
    values: { event },
  };
  window.webkit.messageHandlers.wv.postMessage(message);
}

// 화면을 닫고 url 이동
function closeWindowAndMove(url) {
  const message = {
    command: 'closeWindowAndMove',
    values: { url },
  };
  window.webkit.messageHandlers.wv.postMessage(message);
}

// 로딩표시
function showLoading() {
  const message = {
    command: 'showLoading',
  };
  window.webkit.messageHandlers.wv.postMessage(message);
}

// 로딩숨김
function hideLoading() {
  const message = {
    command: 'hideLoading',
  };
  window.webkit.messageHandlers.wv.postMessage(message);
}

// 카카오 공유하기
function kakaoShare(objectType, kakao) {
  kakao.objectType = objectType;
  const message = {
    command: 'kakaoShare',
    objectType,
    values: kakao,
  };
  window.webkit.messageHandlers.wv.postMessage(message);
}

function login() {
  const message = {
    command: 'login',
  };
  window.webkit.messageHandlers.wv.postMessage(message);
}

function pagePush(url, title) {
  const message = {
    command: 'pagePush',
    values: {
      url,
      title,
    },
  };
  window.webkit.messageHandlers.wv.postMessage(message);
}

// 새 페이지 전체창으로 열기(push)
function fullPagePush(pId, url, closeAllYn) {
  window.webkit.messageHandlers.wv.postMessage({
    command: 'push',
    values: {
      pId,
      url,
      closeAllYn,
    },
  });
}

// 새 페이지 전체창으로 열기(present)
function fullPagePresent(pId, url) {
  window.webkit.messageHandlers.wv.postMessage({
    command: 'present',
    values: {
      pId,
      url,
    },
  });
}

// 현재 창 닫기
function closeCurrentPage() {
  window.webkit.messageHandlers.wv.postMessage({
    command: 'close',
  });
}

// 전체 창 닫기
function closeAllPage() {
  window.webkit.messageHandlers.wv.postMessage({
    command: 'closeAll',
  });
}

// 공유하기
function shareUrl(title, url) {
  window.webkit.messageHandlers.wv.postMessage({
    command: 'share',
    values: { title, url },
  });
}

// 스토어 구매완료 후 창 전체 닫고 수거요청으로 이동
function completeAlertCloseAll(message) {
  window.webkit.messageHandlers.wv.postMessage({
    command: 'completeAlertCloseAll',
    values: {
      text: message,
    },
  });
}

// 스토어 구매실패 후 alert
function paymentFailAlert(payload) {
  const { message, retryCallback } = payload;
  window.webkit.messageHandlers.wv.postMessage({
    command: 'paymentFailAlert',
    values: {
      reason: message,
      paymentCallback: retryCallback,
    },
  });
}

// Braze 이벤트 전송
function brazeEvent(payload) {
  const { eventName, eventValue } = payload;

  const sendData = {
    name: eventName,
    value: eventValue,
  };

  if (eventValue === undefined) {
    delete sendData.value;
  }

  window.webkit.messageHandlers.wv.postMessage({
    command: 'brazeEvent',
    values: sendData,
  });
}

function executeWeb2App(params) {
  // stringify 해서 보낼 것
  window.webkit.messageHandlers.web2app.postMessage(JSON.stringify(params));
}

export default {
  showToast,
  showAlert,
  showConfirm,
  copyToClipboard,
  closeWindow,
  showAlertAndCloseWindow,
  showAlertAndCloseAll,
  sendEvent,
  closeWindowAndMove,
  showLoading,
  hideLoading,
  kakaoShare,
  login,
  pagePush,
  fullPagePush,
  fullPagePresent,
  closeCurrentPage,
  closeAllPage,
  shareUrl,
  completeAlertCloseAll,
  paymentFailAlert,
  brazeEvent,
  executeWeb2App,
};
