// 토스트
function showToast(text, isTop) {
  window.Android.showToast(text, isTop ? 'true' : 'false');
}

// 얼럿
function showAlert(title, message) {
  // eslint-disable-next-line no-alert
  window.Android.alert(title, message);
}

// confirm
function showConfirm({
  title,
  message,
  cancelTitle,
  confirmTitle,
  cancelCallback,
  confirmCallback,
}) {
  window.Android.confirm(
    title,
    message,
    cancelTitle,
    confirmTitle,
    cancelCallback,
    confirmCallback
  );
}

// 클립보드
function copyToClipboard(text, message) {
  window.Android.copyToClipboard(text, message);
}

// 화면 닫기
function closeWindow() {
  window.Android.closeWindow();
}

// 얼럿을 보여주고 화면 닫기
function showAlertAndCloseWindow(text) {
  window.Android.showAlertAndCloseWindow(text);
}

// 얼럿을 보여주고 전체화면 닫기
function showAlertAndCloseAll(text) {
  window.Android.showAlertAndCloseAll(text);
}

// 트래킹 전송
function sendEvent(event) {
  window.Android.sendEvent(JSON.stringify(event));
}

// 화면닫고 url 이동
function closeWindowAndMove(url) {
  window.Android.closeWindowAndMove(url);
}

// 로딩 표시
function showLoading() {
  window.Android.showLoading();
}

// 로딩 숨김
function hideLoading() {
  window.Android.hideLoading();
}

// 카카오 공유하기
function kakaoShare(objectType, kakao) {
  window.Android.kakaoShare(objectType, JSON.stringify(kakao));
}

// 로그인 화면 호출
function login() {
  window.Android.login();
}

// 새 페이지 띄우기 (우 -> 좌 화면전환 및 좌 -> 우)
function pagePush(url, title) {
  window.Android.pagePush(url, title);
}

// 새 페이지 전체창으로 열기(push)
function fullPagePush(pId, url, closeAllYn) {
  window.Android.push(pId, url, closeAllYn);
}

// 새 페이지 전체창으로 열기(present)
function fullPagePresent(pId, url) {
  window.Android.present(pId, url);
}

// 현재 창 닫기
function closeCurrentPage() {
  window.Android.close();
}

// 전체 창 닫기
function closeAllPage() {
  window.Android.closeAll();
}

// 공유하기
function shareUrl(title, url) {
  window.Android.share(title, url);
}

// 스토어 구매완료 후 창 전체 닫고 수거요청으로 이동
function completeAlertCloseAll(message) {
  window.Android.completeAlertCloseAll(message);
}

// 스토어 구매실패 후 alert
function paymentFailAlert(payload) {
  const { message, retryCallback } = payload;
  window.Android.paymentFailAlert(message, retryCallback);
}

// Braze 이벤트 전송
function brazeEvent(payload) {
  if (window.Android.brazeEvent === undefined) {
    return;
  }

  const { eventName, eventValue } = payload;

  if (eventValue === undefined) {
    window.Android.brazeEvent(eventName);
  } else {
    window.Android.brazeEvent(eventName, JSON.stringify(eventValue));
  }
}

function executeWeb2App(params) {
  // stringify 해서 보낼 것
  window.Android.web2app(JSON.stringify(params));
}

export default {
  showToast,
  showAlert,
  showConfirm,
  copyToClipboard,
  closeWindow,
  showAlertAndCloseWindow,
  showAlertAndCloseAll,
  sendEvent,
  closeWindowAndMove,
  showLoading,
  hideLoading,
  kakaoShare,
  login,
  pagePush,
  fullPagePush,
  fullPagePresent,
  closeCurrentPage,
  closeAllPage,
  shareUrl,
  completeAlertCloseAll,
  paymentFailAlert,
  brazeEvent,
  executeWeb2App,
};
