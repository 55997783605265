/* eslint-disable @typescript-eslint/no-empty-function */
import { androidMessageHandler, iOSMessageHandler } from './messageHandlers';

let messageHandler: any = {
  showToast: () => {},
  showAlert: () => {},
  showConfirm: () => {},
  copyToClipboard: () => {},
  closeWindow: () => {},
  showAlertAndCloseWindow: () => {},
  showAlertAndCloseAll: () => {},
  sendEvent: () => {},
  closeWindowAndMove: () => {},
  showLoading: () => {},
  hideLoading: () => {},
  kakaoShare: () => {},
  login: () => {},
  pagePush: () => {},
  fullPagePush: () => {},
  fullPagePresent: () => {},
  closeCurrentPage: () => {},
  closeAllPage: () => {},
  shareUrl: () => {},
  completeAlertCloseAll: () => {},
  paymentFailAlert: () => {},
  brazeEvent: () => {},
  executeWeb2App: () => {},
};

// 메시지 핸들러 설정
export function configureMessageHandler() {
  if (window) {
    const { Android, webkit } = window;
    if (Android) {
      messageHandler = androidMessageHandler;
    } else if (webkit && webkit.messageHandlers && webkit.messageHandlers.wv) {
      messageHandler = iOSMessageHandler;
    }

    return messageHandler;
  }
}

// 메시지를 보낼 수 있는지 확인
function isAvailable() {
  return !!messageHandler;
}

// 메시지를 보낼 수 있는지 확인하고, 액션에 인수를 적용
function call(action, args) {
  if (!isAvailable()) {
    return;
  }
  action(args);
}

// 토스트
function showToast(text, topYn = 'N') {
  const isTop = topYn === 'Y';
  messageHandler.showToast(`${text}`, isTop);
}

// 얼럿
function showAlert(title, message) {
  messageHandler.showAlert(`${title}`, `${message || ''}`);
}

// confirm
function showConfirm({
  title,
  message,
  cancelTitle,
  confirmTitle,
  cancelCallback,
  confirmCallback,
}) {
  messageHandler.showConfirm({
    title,
    message,
    cancelTitle,
    confirmTitle,
    cancelCallback,
    confirmCallback,
  });
}

// 클립보드
function copyToClipboard(text, message) {
  messageHandler.copyToClipboard(text, message);
}

// 화면 닫기
function closeWindow() {
  messageHandler.closeWindow();
}

// 얼럿을 보여주고 화면 닫기
function showAlertAndCloseWindow(text) {
  messageHandler.showAlertAndCloseWindow(`${text}`);
}

// 얼럿을 보여주고 화면 전체 닫기
function showAlertAndCloseAll(text) {
  messageHandler.showAlertAndCloseAll(`${text}`);
}

// 트레킹 전송
function sendEvent(event) {
  messageHandler.sendEvent(event);
}

// 화면 닫고 url 이동
function closeWindowAndMove(url) {
  messageHandler.closeWindowAndMove(url);
}

function showLoading() {
  messageHandler.showLoading();
}

function hideLoading() {
  messageHandler.hideLoading();
}

function kakaoShare(objectType, kakao) {
  messageHandler.kakaoShare(objectType, kakao);
}

function login() {
  messageHandler.login();
}

function pagePush(url, title) {
  messageHandler.pagePush(url, title);
}

// 새 페이지 전체창으로 열기(push)
function fullPagePush(pId, url, closeAllYn = 'N') {
  messageHandler.fullPagePush(pId, url, closeAllYn);
}

// 새 페이지 전체창으로 열기(present)
function fullPagePresent(pId, url) {
  messageHandler.fullPagePresent(pId, url);
}

// 현재 창 닫기
function closeCurrentPage() {
  messageHandler.closeCurrentPage();
}

// 전체 창 닫기
function closeAllPage() {
  messageHandler.closeAllPage();
}

// 전체 창 닫기
function shareUrl(title, url) {
  messageHandler.shareUrl(title, url);
}

// 스토어 구매완료 후 창 전체 닫고 수거요청으로 이동
function completeAlertCloseAll(message) {
  messageHandler.completeAlertCloseAll(message);
}

// 스토어 구매실패 후 alert
function paymentFailAlert(payload) {
  messageHandler.paymentFailAlert(payload);
}

// Braze 이벤트 전송
function brazeEvent(payload: { eventName: string; eventValue?: any; purchase?: any }) {
  if (messageHandler.brazeEvent !== undefined) {
    messageHandler.brazeEvent(payload);
  }
}

function executeWeb2App(params) {
  // stringify 해서 보낼 것
  messageHandler.executeWeb2App(params);
}

export {
  showToast,
  showAlert,
  showConfirm,
  copyToClipboard,
  closeWindow,
  showAlertAndCloseWindow,
  showAlertAndCloseAll,
  sendEvent,
  closeWindowAndMove,
  showLoading,
  hideLoading,
  kakaoShare,
  login,
  pagePush,
  fullPagePush,
  fullPagePresent,
  closeCurrentPage,
  closeAllPage,
  shareUrl,
  completeAlertCloseAll,
  paymentFailAlert,
  brazeEvent,
  executeWeb2App,
};

export default call;
